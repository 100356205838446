import classnames from 'classnames'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { CharType } from '../../lib/statuses'

type Props = {
  value?: string
  type?: CharType
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
}

export const Cell = ({
  value,
  type: status,
  isRevealing,
  isCompleted,
  position = 0,
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-14 h-14 border-solid border-3 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white ', //bevel bevel-tl bevel-br bevel-bl bevel-tr
    {
      'bg-gray-200 dark:bg-card border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'absent shadowed bg-slate-400 dark:bg-key text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'indirect-score shadowed bg-gradient-to-b from-yellow-500 via-yellow-600 to-yellow-700 text-white border-yellow-500':
        status === 'indirect-score',
      'direct-score shadowed bg-gradient-to-b from-red-500 via-red-600 to-red-700 text-white border-red-500':
        status === 'direct-score',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && isHighContrast,
      'present shadowed bg-cyan-500 text-white border-cyan-500':
        status === 'present' && isHighContrast,
      'correct shadowed bg-gradient-to-b from-green-700 via-green-800 to-green-900 text-white border-green-500':
        status === 'correct' && !isHighContrast,
      'present shadowed bg-gradient-to-b from-yellow-500 via-yellow-600 to-yellow-700 text-white border-yellow-500':
        status === 'present' && !isHighContrast,
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  )

  return (
    <div className={classes} style={{ animationDelay }}>
      <div className="letter-container" style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
