import { UAParser } from 'ua-parser-js'

import { MAX_CHALLENGES } from '../constants/settings'
import { GAME_TITLE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { solutionIndex, unicodeSplit } from './words'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  solution: string,
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  handleShareFailure: () => void
) => {
  const textToShare =
    `${GAME_TITLE} ${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? '*' : ''}\n\n` +
    generatePerLetterEmojiGrid(
      solution,
      guesses,
      getPerLetterEmojiTiles(isDarkMode, isHighContrastMode)
    )?.replaceAll(',', '')

  const shareData = { text: textToShare }

  let shareSuccess = false

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateEmojiGrid = (
  solution: string,
  guesses: string[],
  tiles: string[]
) => {
  return guesses
    .map((guess) => {
      const { statuses } = getGuessStatuses(solution, guess)
      const splitGuess = unicodeSplit(guess)

      return splitGuess
        .map((_, i) => {
          switch (statuses[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}

export const generatePerLetterEmojiGrid = (
  solution: string,
  guesses: string[],
  tiles: { direct: string; indirect: string }[]
) => {
  return guesses
    .map((guess) => {
      const { statuses, stats } = getGuessStatuses(solution, guess)
      const splitGuess = unicodeSplit(guess)

      const row = splitGuess.map((_, i) => {
        switch (statuses[i]) {
          case 'correct':
            return tiles[i].direct
          case 'present':
            return tiles[solution.indexOf(guess[i])].indirect //tiles[1]
          default:
            return colorTileEmojis.white
        }
      })

      row.push(numberTileEmojisArray[stats.directHits])
      row.push(numberTileEmojisArray[stats.indirectHits])
      row.join('')
      return row
    })
    .join('\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(
    isHighContrastMode ? colorTileEmojis['orange'] : colorTileEmojis['green']
  )
  tiles.push(
    isHighContrastMode ? colorTileEmojis['blue'] : colorTileEmojis['yellow']
  )
  tiles.push(isDarkMode ? colorTileEmojis['black'] : colorTileEmojis['white'])
  return tiles
}

const getPerLetterEmojiTiles = (
  isDarkMode: boolean,
  isHighContrastMode: boolean
) => {
  let tiles: { direct: string; indirect: string }[] = []
  tiles.push({
    direct: colorTileEmojis['purple'],
    indirect: colorCircleEmojis['purple'],
  })
  tiles.push({
    direct: colorTileEmojis['blue'],
    indirect: colorCircleEmojis['blue'],
  })
  tiles.push({
    direct: colorTileEmojis['green'],
    indirect: colorCircleEmojis['green'],
  })
  tiles.push({
    direct: colorTileEmojis['yellow'],
    indirect: colorCircleEmojis['yellow'],
  })
  tiles.push({
    direct: colorTileEmojis['orange'],
    indirect: colorCircleEmojis['orange'],
  })
  // tiles.push(colorTileEmojis['purple'])
  // tiles.push(colorCircleEmojis['blue'])
  // tiles.push(colorCircleEmojis['green'])
  // tiles.push(colorCircleEmojis['yellow'])
  // tiles.push(colorCircleEmojis['orange'])
  return tiles
}

const colorTileEmojis = {
  white: '⬜',
  yellow: '🟨',
  orange: '🟧',
  red: '🟥',
  purple: '🟪',
  blue: '🟦',
  green: '🟩',
  brown: '🟫',
  black: '⬛',
}

const colorCircleEmojis = {
  red: '🔴',
  orange: '🟠',
  yellow: '🟡',
  green: '🟢',
  blue: '🔵',
  purple: '🟣',
  brown: '🟤',
  black: '⚫',
  white: '⚪',
}

const numberTileEmojis = {
  0: '0️⃣',
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣',
  10: '🔟',
}

const numberTileEmojisArray = [
  '0️⃣',
  '1️⃣',
  '2️⃣',
  '3️⃣',
  '4️⃣',
  '5️⃣',
  '6️⃣',
  '7️⃣',
  '8️⃣',
  '9️⃣',
  '🔟',
]

const indirectHitTile = '🔳' //

// ❤️
// 🩷
// 🧡
// 💛
// 💚
// 💙
// 🩵
// 💜
// 🤎
// 🖤
// 🩶
// 🤍
