import { unicodeSplit } from './words'

export type CharType =
  | 'absent'
  | 'present'
  | 'correct'
  | 'direct-score'
  | 'indirect-score'

export const getStatuses = (
  solution: string,
  guesses: string[]
): { [key: string]: CharType } => {
  const charObj: { [key: string]: CharType } = {}
  // const splitSolution = unicodeSplit(solution)

  // guesses.forEach((word) => {
  //   unicodeSplit(word).forEach((letter, i) => {
  //     if (!splitSolution.includes(letter)) {
  //       // make status absent
  //       return (charObj[letter] = 'absent')
  //     }

  //     if (letter === splitSolution[i]) {
  //       //make status correct
  //       return (charObj[letter] = 'correct')
  //     }

  //     if (charObj[letter] !== 'correct') {
  //       //make status present
  //       return (charObj[letter] = 'present')
  //     }
  //   })
  // })

  return charObj
}

export const getGuessStatuses = (
  solution: string,
  guess: string
): {
  statuses: CharType[]
  stats: { directHits: number; indirectHits: number }
} => {
  const splitSolution = unicodeSplit(solution)
  const splitGuess = unicodeSplit(guess)

  const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: CharType[] = Array.from(Array(guess.length))

  let indirectHits = 0
  let directHits = 0
  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      directHits += 1
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      indirectHits += 1
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })

  return { statuses, stats: { directHits, indirectHits } }
}
